import $ from 'jquery';

const enterDiseaseSearch = () => {
  $('.enter-disease-search').on('click', function(event) {
    $('#disease_sy_2_disease_name_kanji').val($('#disease-kanji-' + this.id).text().trim());
    $('#disease_sy_2_disease_name_katakana').val($('#disease-katakana-' + this.id).text().trim());
    $('#disease_sy_2_disease_name_code').val($('#disease-code-' + this.id).text().trim());
  });
}

const clearDiseaseSearch = () => {
  $('#clear-disease-search').on('click', function (event) {
    $('#disease-search-form').val('');
    $('#disease_sy_2_disease_name_kanji').val('');
    $('#disease_sy_2_disease_name_katakana').val('');
    $('#disease_sy_2_disease_name_code').val('');
  });
}

const enterTreatmentSearch = () => {
  $('.enter-treatment-search').on('click', function (event) {
    // console.log($('#treatment-kanji-' + this.id).text());
    // $('#treatment_si_2_treatment_type').val($('#treatment-tensuran-syukeisaki-' + this.id).text().trim().substring(0, 2));
    $('#treatment_si_2_treatment_type').val($('#treatment-tensuran-syukeisaki-' + this.id).text().trim());
    $('#treatment_si_4_treatment_action_kanji').val($('#treatment-kanji-' + this.id).text().trim());
    $('#treatment_si_4_treatment_action_katakana').val($('#treatment-katakana-' + this.id).text().trim());
    $('#treatment_si_4_treatment_action_code').val($('#treatment-code-' + this.id).text().trim());
    $('#treatment_si_6_point').val($('#treatment-point-' + this.id).text().trim());
    $('#treatment_chu_kasan_code').val($('#treatment-chu-kasan-code-' + this.id).text().trim());
    $('#treatment_kokujitou_shikibetsukubun').val($('#treatment-kokujitou-shikibetsukubun-' + this.id).text().trim());
    $('#treatment_chu_kasan_order').val($('#treatment-chu-kasan-order-' + this.id).text().trim());
  });
}

const clearTreatmentSearch = () => {
  $('#clear-treatment-search').on('click', function (event) {
    $('#treatment-search-form').val('');
    $('#treatment_si_4_treatment_action_kanji').val('');
    $('#treatment_si_4_treatment_action_katakana').val('');
    $('#treatment_si_4_treatment_action_code').val('');
    $('#treatment_si_6_point').val('');
  });
}

export { enterDiseaseSearch, clearDiseaseSearch, enterTreatmentSearch, clearTreatmentSearch };
