import $ from 'jquery';

const enterCommentKyokuma = () => {
  $('#enter-kyokuma').on('click', function (event) {
    $('#treatment_si_8_comment1').val('830100322');
    $('#treatment_si_9_moji_data1').val('局所麻酔');
  });
}

const clearComments = () => {
  $('#clear-comments').on('click', function (event) {
    $('#treatment_si_8_comment1').val('');
    $('#treatment_si_9_moji_data1').val('');
    $('#treatment_si_10_comment2').val('');
    $('#treatment_si_11_moji_data2').val('');
    $('#treatment_si_12_comment3').val('');
    $('#treatment_si_13_moji_data3').val('');
  });
}

export { enterCommentKyokuma, clearComments };
