import $ from 'jquery';

const hankakuZenkakuTranslateSymbol = () => {
  $('#recipient_ho_3_hihokenja_symbol').on('change', function(){
      var str = $(this).val();
      str = str.replace( /[A-Za-z0-9-!"#$%&'()=<>,.?_\[\]{}@^~\\]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) + 65248);
      });
      $(this).val(str);
  });
}

const hankakuZenkakuTranslateNumber = () => {
  $('#recipient_ho_4_hihokenja_number').on('change', function(){
      var str = $(this).val();
      str = str.replace( /[A-Za-z0-9-!"#$%&'()=<>,.?_\[\]{}@^~\\]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) + 65248);
      });
      $(this).val(str);
  });
}

export { hankakuZenkakuTranslateSymbol, hankakuZenkakuTranslateNumber };
